import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import MediaQueries from '../utils/media-queries';
import Colours from '../utils/colours';
import { Link } from 'gatsby';

const PostItemContainer = styled.article`
  border-bottom: 1px solid ${Colours.offWhite};
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 2fr 5fr;
  grid-template-areas: 'date img content';
  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    grid-area: content;
    :visited {
      color: inherit;
    }
  }
  h4 {
    padding: 0 1rem;
  }
  @media only screen and (max-width: ${MediaQueries.fish}) {
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr !important;
    grid-template-areas: 'img' 'date' 'content' !important;
  }
`;

const BlogTime = styled.time`
  grid-area: 'date';
  font-family: 'Catamaran', sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  align-self: center;
  justify-self: center;
  @media only screen and (max-width: 800px) {
    justify-self: flex-end;
  }
`;

const More = styled.p`
  align-self: flex-end;
  display: block;
  white-space: nowrap; // stop text from wrapping on mobile - still needs testing
  margin: 1rem 1rem 0 0.2rem;
  padding: 0.5rem 0 0;
  text-transform: lowercase;
  border-bottom: 3px solid ${Colours.secondary2};
  //font-size: 0.9rem;
  transition: padding 0.3s cubic-bezier(0, 1.5, 0.3, 1.5);
  :hover {
    padding: 0.5rem 2rem 0 0;
  }
`;

const RenderBlogItem = (post) => {
  return (
    <PostItemContainer
      className='item'
      style={
        post.frontmatter.coverimage !== null
          ? null
          : {
              gridTemplateColumns: '1fr 0fr 7fr',
            }
      }
    >
      <BlogTime>
        {post.frontmatter.updated !== 'Invalid date'
          ? post.frontmatter.updated
          : post.frontmatter.date}
      </BlogTime>
      {post.frontmatter.coverimage === null ? null : (
        <Link
          to={post.fields.slug}
          style={{
            gridArea: 'img',
            height: 'min-content',
            alignSelf: 'center',
          }}
        >
          <Img fluid={post.frontmatter.coverimage.childImageSharp.fluid} />
        </Link>
      )}
      <Link to={post.fields.slug}>
        <h4 style={{ padding: '0' }}>{post.frontmatter.title}</h4>
        <p style={{ margin: 0, padding: '0 1rem' }}>
          {post.frontmatter.description || post.excerpt}
        </p>
        <More>Read more...</More>
      </Link>
    </PostItemContainer>
  );
};

export default (props) => {
  return RenderBlogItem(props.post);
};
