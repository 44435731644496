import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostItemArchive from '../components/PostItemArchive';
//import styled from 'styled-components';
import SEO from '../components/SEO/SEO';

export default ({ data }) => {
  const SortBlogPosts = (a, b) => {
    if (a.node.frontmatter.unformatteddate < b.node.frontmatter.unformatteddate) {
      return 1;
    }
    if (a.node.frontmatter.unformatteddate > b.node.frontmatter.unformatteddate) {
      return -1;
    }
    return 0;
  };

  const SortedBlogs = data.Posts.edges.sort(SortBlogPosts);

  const BlogPosts = SortedBlogs.map(({ node: post }) =>
    post.frontmatter.posttype === 'Blog' ? (
      <PostItemArchive key={post.id} post={post} type={post.frontmatter.posttype} />
    ) : null
  );
  return (
    <Layout>
      <SEO title='Blog' pathname='/blog/' />
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 1rem' }}>
        <h2>Article Archives</h2>
        <p>
          Have a look through all of my blog posts and articles. From web development to business
          development, there's something for everyone here!
        </p>
        {BlogPosts}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPageQuery {
    Posts: allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          timeToRead
          frontmatter {
            date(formatString: "DD MMMM")
            unformatteddate: date
            updated(formatString: "DD MMMM")
            title
            posttype
            description
            priority
            coverimage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
